import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import { connect } from "react-redux";
import Footer from "../shared/footer/footer";
import Header from "../shared/header/header";
import Login from "../authentication/login/login";
import Signup from "../authentication/signup/signup";

class CareerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  showPremiumModal = () => {
    this.setState({ popupVisible: true });
  };

  closePopup = () => {
    this.setState({ popupVisible: false });
  };

  render() {
    return (
      <>

    <Header handleLoginModal={this.handleLoginModal} handleSignupModal={this.handleSignupModal} />
    <Login isModalVisible={this.state.isLoginModalVisible} handleModal={this.handleLoginModal} />
    <Signup isModalVisible={this.state.isSignupModalVisible} handleModal={this.handleSignupModal} />


      <section className="home-banner work-banner">

        <img src="../images/career-img.svg" alt=""/>


        <div className="tag">
          <h1>Join us </h1>
          <h2>be part of the growing platform for fanbase</h2>

        </div>

      </section>

  <section className="aboutus background1">

    <div className="container">

            <div className="feature_boxes row_parent">
               <p>Fanify is one of the fastest growing music technology companies in the world.</p>
               <p>We are constantly expanding our core team to help grow global expansion and bring our world class technology to musicians, DJs and record labels everywhere.</p>
               <p>If you believe you possess the skills, drive and vision to join us on this journey, please contact us with your resume today. Share at careers@fanify.io</p>
            </div>


    </div>
  </section>

  <Footer/>

      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.Auth.token,
});

export default withRouter(connect(mapStateToProps)(CareerPage));
